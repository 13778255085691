import { Box, IconButton, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import React, { useContext } from 'react';
import { PlannerContext, ProjectContext } from '../../../providers';

export const PlannerSearch = () => {
  const { performingAction } = useContext(ProjectContext);
  const {
    selectedTags,
    searchValue,
    setSearchValue,
    handleSetEditTask,
    handleSetEditWorkflow,
    selectedTab,
  } = useContext(PlannerContext);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (selectedTab === 'task') {
        handleSetEditTask({ ...{ tags: selectedTags }, name: searchValue });
      } else if (selectedTab === 'workflow') {
        handleSetEditWorkflow({ ...{ tags: selectedTags }, name: searchValue });
      }
    }
  };

  return (
    <Box mt={2}>
      <TextField
        fullWidth
        placeholder={'Search'}
        value={searchValue}
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
        onKeyDown={handleKeyPress}
        disabled={performingAction}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                setSearchValue('');
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};
