import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { TypeFieldCopy, TypePlannerTypes } from '../../../types';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { PlannerContext } from '../../../providers';

export type ParentFieldFilterDefaults<Types> = {
  [K in keyof Types]: TypeFieldCopy<Types[K]>;
};

const headerNames: ParentFieldFilterDefaults<TypePlannerTypes> = {
  task: {
    name: 'Name',
    completedAt: 'Completed At',
    deletedAt: 'Deleted At',
    dueAt: 'Due Date',
    snoozeUntil: 'Snoozed Until',
    durationEstimate: 'Duration',
    updatedAt: 'Last Updated',
    priorityValue: 'Priority',
  },
  workflow: {
    name: 'Name',
    updatedAt: 'Updated At',
    isRunning: 'Running',
  },
};

export const PlannerFilterChips = () => {
  const { selectedTab, allFilters, handleSetSelectedField } =
    useContext(PlannerContext);

  return (
    <>
      {allFilters[selectedTab].showFields.map((field, index) => (
        <Button
          sx={{ mr: 1, mb: 1, borderRadius: 20, textTransform: 'none' }}
          key={index}
          size={'small'}
          variant={
            allFilters[selectedTab].selectedField === field
              ? 'contained'
              : 'outlined'
          }
          onClick={() => handleSetSelectedField(selectedTab, field)}
          endIcon={
            allFilters[selectedTab].selectedField === field ? (
              allFilters[selectedTab].isDescending ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )
            ) : null
          }
        >
          {headerNames[selectedTab][field]}
        </Button>
      ))}
    </>
  );
};
