import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { PlannerContext } from '../../../providers';
import { TypeFieldToggle } from '../../../types/coach/FieldToggle';
import { TypePlannerTypes } from '../../../types';
import {
  CheckCircle,
  CheckCircleOutlined,
  DeleteForever,
  DeleteOutline,
  Snooze,
  SnoozeOutlined,
} from '@mui/icons-material';

export type ParentFieldToggles<Types> = {
  [K in keyof Types]: TypeFieldToggle<Types[K]>;
};

const toggles: ParentFieldToggles<TypePlannerTypes> = {
  task: {
    completedAt: {
      copy: 'Show Completed',
      activeIcon: <CheckCircle />,
      inactiveIcon: <CheckCircleOutlined />,
    },
    deletedAt: {
      copy: 'Show Deleted',
      activeIcon: <DeleteForever />,
      inactiveIcon: <DeleteOutline />,
    },
    snoozeUntil: {
      copy: 'Show Snoozed',
      activeIcon: <Snooze />,
      inactiveIcon: <SnoozeOutlined />,
    },
  },
  workflow: {},
};
export const PlannerToggleChips = () => {
  const { selectedTab, allFilters, handleShowHideField } =
    useContext(PlannerContext);
  return (
    <>
      {allFilters[selectedTab].toggleFields.map((field) => {
        const isActive = allFilters[selectedTab].showFields.includes(field);
        return (
          <Button
            key={`toggle-${selectedTab}-${field}`}
            sx={{
              mr: 1,
              mb: 1,
              borderRadius: 20,
              textTransform: 'none',
            }}
            size={'small'}
            variant={isActive ? 'contained' : 'outlined'}
            onClick={() => handleShowHideField(selectedTab, field, !isActive)}
            endIcon={
              isActive
                ? toggles[selectedTab][field].activeIcon
                : toggles[selectedTab][field].inactiveIcon
            }
          >
            {toggles[selectedTab][field]?.copy}
          </Button>
        );
      })}
    </>
  );
};
