import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  TypeCalendarEvent,
  TypeDocument,
  TypeFieldActiveFilters,
  TypeFieldFilterDefaults,
  TypePlannerTypes,
  TypeTag,
  TypeTask,
  TypeWorkflow,
} from '../../types';
import {
  useGetTasks,
  useGetWorkflows,
  usePostTask,
  usePostWorkflow,
} from '../../api';
import { ProjectContext } from '../ProjectProvider';
import { DialogContext } from '../DialogProvider';
import { parseISO } from 'date-fns';
import { stableSort } from '../../components';
import { TimeContext } from '../TimeProvider';
import { uniq, without } from 'lodash';
import { useSubscription } from 'react-stomp-hooks';
import { AuthContext } from '../AuthProvider';

export type ParentFieldActiveFilters<Types> = {
  [K in keyof Types]: TypeFieldActiveFilters<Types[K]>;
};
export type ParentFieldFilterDefaults<Types> = {
  [K in keyof Types]: TypeFieldFilterDefaults<Types[K]>;
};

const allDefaults: ParentFieldFilterDefaults<TypePlannerTypes> = {
  task: {
    defaultFields: ['name', 'dueAt', 'updatedAt', 'priorityValue'],
    fieldIsDescending: {
      name: false,
      dueAt: false,
      updatedAt: true,
      priorityValue: true,
      completedAt: true,
      deletedAt: true,
      snoozeUntil: false,
    },
    defaultSortField: 'priorityValue',
  },
  workflow: {
    defaultFields: ['name', 'updatedAt', 'isRunning'],
    fieldIsDescending: {
      name: false,
      updatedAt: true,
      isRunning: true,
    },
    defaultSortField: 'updatedAt',
  },
};

type PlannerContextType = {
  // global view
  selectedTab: keyof TypePlannerTypes;
  setSelectedTab: (_tab: keyof TypePlannerTypes) => void;
  showFilters: boolean;
  setShowFilters: (_showFilters: boolean) => void;
  allFilters: ParentFieldActiveFilters<TypePlannerTypes>;
  handleShowHideField: <K extends keyof TypePlannerTypes>(
    _typeKey: K,
    _field: keyof TypePlannerTypes[K],
    _checked: boolean,
  ) => void;
  handleSetSelectedField: <K extends keyof TypePlannerTypes>(
    _typeKey: K,
    _field: keyof TypePlannerTypes[K],
  ) => void;
  showChips: boolean;
  setShowChips: (_checked: boolean) => void;
  selectedTags: TypeTag[];
  handleSelectTag: (_tag: TypeTag) => void;
  searchValue: string;
  setSearchValue: (_value: string) => void;
  searchValueRef: React.RefObject<HTMLInputElement | null> | null;
  // task view
  activeTask: TypeTask | null;
  tasks: TypeTask[];
  setTasks: (_tasks: TypeTask[]) => void;
  handleSetEditTask: (_task: TypeTask) => void;
  handleSaveTask: (_updatedTask: TypeTask) => void;
  // workflow view
  activeWorkflow: TypeWorkflow | null;
  workflows: TypeWorkflow[];
  setWorkflows: (_workflows: TypeWorkflow[]) => void;
  handleSetEditWorkflow: (_workflow: TypeWorkflow) => void;
  handleSaveWorkflow: (_updatedWorkflow: TypeWorkflow) => void;
  // documents
  documents: TypeDocument[];
  // events
  events: TypeCalendarEvent[];

  isPlannerReady: boolean;
};

export const PlannerContext = createContext<PlannerContextType>({
  // global view
  selectedTab: 'task',
  setSelectedTab: () => {},
  showFilters: true,
  setShowFilters: () => {},
  allFilters: {
    task: {
      showFields: [],
      toggleFields: [],
      selectedField: 'id',
      isDescending: false,
    },
    workflow: {
      showFields: [],
      toggleFields: [],
      selectedField: 'id',
      isDescending: false,
    },
  },
  handleShowHideField: (_typeKey, _field, _checked) => {},
  handleSetSelectedField: (_typeKey, _field) => {},
  showChips: true,
  setShowChips: () => {},
  selectedTags: [],
  handleSelectTag: () => {},
  searchValue: '',
  setSearchValue: () => {},
  searchValueRef: null,
  activeTask: null,
  // task view
  tasks: [],
  setTasks: () => {},
  handleSetEditTask: () => {},
  handleSaveTask: () => {},
  // workflow view
  activeWorkflow: null,
  workflows: [],
  setWorkflows: () => {},
  handleSetEditWorkflow: () => {},
  handleSaveWorkflow: () => {},
  // documents
  documents: [],
  // events
  events: [],
  isPlannerReady: false,
});

export type PlannerProviderProps = {
  children: React.ReactNode;
};

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  const { profile } = useContext(AuthContext);
  const { setPerformingAction, setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { currentMinute } = useContext(TimeContext);

  const [activeTask, setActiveTask] = useState<TypeTask | null>(null);
  const [activeWorkflow, setActiveWorkflow] = useState<TypeWorkflow | null>(
    null,
  );
  const [showChips, setShowChips] = useState<boolean>(true);
  const [selectedTags, setSelectedTags] = useState<TypeTag[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tasks, setTasks] = useState<TypeTask[]>([]);
  const [workflows, setWorkflows] = useState<TypeWorkflow[]>([]);
  const [selectedTab, setSelectedTab] =
    useState<keyof TypePlannerTypes>('task');

  const [allFilters, setAllFilters] = useState<
    ParentFieldActiveFilters<TypePlannerTypes>
  >({
    task: {
      showFields: ['name', 'dueAt', 'updatedAt', 'priorityValue'],
      toggleFields: ['completedAt', 'snoozeUntil', 'deletedAt'],
      selectedField: allDefaults['task'].defaultSortField,
      isDescending:
        allDefaults['task'].fieldIsDescending[
          allDefaults['task'].defaultSortField
        ] ?? false,
    },
    workflow: {
      showFields: ['name', 'updatedAt', 'isRunning'],
      toggleFields: [],
      selectedField: allDefaults['workflow'].defaultSortField,
      isDescending:
        allDefaults['workflow'].fieldIsDescending[
          allDefaults['workflow'].defaultSortField
        ] ?? false,
    },
  });

  const {
    data: tasksData,
    isLoading: tasksLoading,
    refetch: refetchTasks,
  } = useGetTasks();
  const {
    data: workflowsData,
    isLoading: workflowsLoading,
    refetch: refetchWorkflows,
  } = useGetWorkflows();
  const { mutate: updateTask } = usePostTask();
  const { mutate: updateWorkflow } = usePostWorkflow();

  const searchValueRef = useRef<HTMLInputElement | null>(null);

  useSubscription(`/user/${profile?.id ?? 0}/topic/planner`, (_message) => {
    console.log('refetching tasks and workflows with profile');
    refetchTasks();
    refetchWorkflows();
  });

  useEffect(() => {
    console.log(
      'sorting',
      tasksData,
      allFilters['task'].selectedField,
      allFilters['task'].isDescending,
      selectedTags,
    );
    if (!tasksData) {
      return;
    }
    if (searchValue) {
      const filteredTasks = tasksData
        .filter((task) => task.name?.includes(searchValue))
        .filter((task) =>
          allFilters['task'].showFields.includes('deletedAt')
            ? true
            : !task.deletedAt,
        );
      setTasks(filteredTasks);
      return;
    }
    const filteredTasks = tasksData
      .filter(
        (task) =>
          selectedTags.length === 0 ||
          selectedTags.some((t) => task.tags?.some((tt) => tt.id === t.id)),
      )
      .filter((task) =>
        allFilters['task'].showFields.includes('completedAt')
          ? true
          : !task.completedAt,
      )
      .filter((task) =>
        allFilters['task'].showFields.includes('deletedAt')
          ? true
          : !task.deletedAt,
      )
      .filter((task) =>
        allFilters['task'].showFields.includes('snoozeUntil')
          ? true
          : !task.snoozeUntil || parseISO(task.snoozeUntil) <= currentMinute,
      );
    const sortedTasks = stableSort<TypeTask>(filteredTasks, {
      property: allFilters['task'].selectedField,
      isDescending: allFilters['task'].isDescending,
    });
    setTasks(sortedTasks);
  }, [allFilters['task'], tasksData, searchValue, selectedTags, currentMinute]);

  useEffect(() => {
    if (!workflowsData) {
      return;
    }
    if (searchValue) {
      const filteredWorkflows = workflowsData.filter((workflow) =>
        workflow.name?.includes(searchValue),
      );
      setWorkflows(filteredWorkflows);
      return;
    }
    const filteredWorkflows = workflowsData.filter(
      (workflow) =>
        selectedTags.length === 0 ||
        selectedTags.some((t) => workflow.tags?.some((tt) => tt.id === t.id)),
    );
    const sortedWorkflows = stableSort<TypeWorkflow>(filteredWorkflows, {
      property: allFilters['workflow'].selectedField,
      isDescending: allFilters['workflow'].isDescending,
    });
    setWorkflows(sortedWorkflows);
  }, [workflowsData, allFilters['workflow'], selectedTags, searchValue]);

  const handleSetEditTask = (task: TypeTask) => {
    setActiveTask(task);
    openDialog('taskDialog');
  };

  const handleSetEditWorkflow = (workflow: TypeWorkflow) => {
    setActiveWorkflow(workflow);
    openDialog('workflowDialog');
  };

  const handleSaveTask = (updatedTask: TypeTask) => {
    setPerformingAction(true);
    updateTask(updatedTask, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveTask(data);
        closeDialog('taskDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleSaveWorkflow = (updatedWorkflow: TypeWorkflow) => {
    setPerformingAction(true);
    updateWorkflow(updatedWorkflow, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveWorkflow(data);
        closeDialog('workflowDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleShowHideField = <K extends keyof TypePlannerTypes>(
    typeKey: K,
    field: keyof TypePlannerTypes[K],
    checked: boolean,
  ) => {
    if (checked) {
      setAllFilters({
        ...allFilters,
        [typeKey]: {
          ...allFilters[typeKey],
          showFields: uniq([...allFilters[typeKey].showFields, field]),
          selectedField: field,
          isDescending: allDefaults[typeKey][field],
        },
      });
    } else {
      const removingField = field === allFilters[typeKey].selectedField;
      const defaultField = allDefaults[typeKey].defaultSortField;
      setAllFilters({
        ...allFilters,
        [typeKey]: {
          ...allFilters[typeKey],
          showFields: without(allFilters[typeKey].showFields, field),
          selectedField: removingField
            ? defaultField
            : allFilters[typeKey].selectedField,
          isDescending: removingField
            ? allDefaults[typeKey].fieldIsDescending[defaultField]
            : allFilters[typeKey].isDescending,
        },
      });
    }
  };

  const handleSetSelectedField = <K extends keyof TypePlannerTypes>(
    typeKey: K,
    field: keyof TypePlannerTypes[K],
  ) => {
    const selectingField = field !== allFilters[typeKey].selectedField;
    setAllFilters({
      ...allFilters,
      [typeKey]: {
        ...allFilters[typeKey],
        selectedField: field,
        isDescending: selectingField
          ? allDefaults[typeKey].fieldIsDescending[field]
          : !allFilters[typeKey].isDescending,
      },
    });
  };

  const handleSelectTag = (tag: TypeTag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(without(selectedTags, tag));
    } else {
      setSelectedTags(uniq(selectedTags.concat([tag])));
    }
  };

  return (
    <PlannerContext.Provider
      value={{
        // global view
        selectedTab,
        setSelectedTab,
        showFilters,
        setShowFilters,
        allFilters,
        handleShowHideField,
        handleSetSelectedField,
        showChips,
        setShowChips,
        selectedTags,
        handleSelectTag,
        searchValue,
        setSearchValue,
        searchValueRef,
        // task view
        activeTask,
        tasks,
        setTasks,
        handleSetEditTask,
        handleSaveTask,
        // workflow view
        activeWorkflow,
        workflows,
        setWorkflows,
        handleSetEditWorkflow,
        handleSaveWorkflow,
        // documents
        documents: [],
        // events
        events: [],
        isPlannerReady: !tasksLoading && !workflowsLoading,
      }}
    >
      {children}
    </PlannerContext.Provider>
  );
};
