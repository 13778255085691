import React, { useContext } from 'react';
import { EditTaskCard, TabView, TaskList, WorkflowList } from '../../index';
import { PlannerContext, PlannerProvider } from '../../../providers';
import { Container } from '@mui/material';
import { TagsAndFilters } from './';
import { PlannerSearch } from './PlannerSearch';
import { Checklist, Event, FormatListNumbered } from '@mui/icons-material';
import { EditWorkflowCard } from '../Workflow';
import { EventList } from '../Event';

export const PlannerWrapper = () => {
  const { setSelectedTab } = useContext(PlannerContext);

  const tabs = [
    { name: 'Tasks', icon: <Checklist />, component: TaskList },
    {
      name: 'Workflows',
      icon: <FormatListNumbered />,
      component: WorkflowList,
    },
    {
      name: 'Events',
      icon: <Event />,
      component: EventList,
    },
  ];

  const handleSetSelectedTab = (selectedTab: number) => {
    setSelectedTab(selectedTab === 0 ? 'task' : 'workflow');
  };

  return (
    <Container>
      <TagsAndFilters />
      <PlannerSearch />
      <TabView tabs={tabs} onSelectTab={handleSetSelectedTab} />
      <EditTaskCard />
      <EditWorkflowCard />
    </Container>
  );
};

export const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerWrapper />
    </PlannerProvider>
  );
};
